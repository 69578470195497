<template>
  <base-dialog
    :title="open ? 'Abrir caja' : 'Cerrar caja'"
    no_icon
    width="700"
    card_id="cash-close"
    basic_btn
    @save="save"
    :disabled="disabled"
    ref="dialog"
  >
    <v-row>
      <v-col cols="12" md="6">
        <v-simple-table>
          <tr>
            <th
              class="text-uppercase text-decoration-underline"
              style="color: var(--v-primary-base) !important"
            >
              Cantidad
            </th>
            <th
              class="text-uppercase text-decoration-underline"
              style="color: var(--v-primary-base) !important"
            >
              Unidad
            </th>
            <th
              class="text-uppercase text-decoration-underline"
              style="color: var(--v-primary-base) !important"
            >
              Valor
            </th>
          </tr>
          <tbody>
            <tr
              v-for="(c, i) in Object.keys(form.cash).sort(sortNumbers)"
              :key="i"
            >
              <td width="50">
                <v-text-field
                  dense
                  class="colorBarrita"
                  hide-details
                  v-model="form.cash[c]"
                ></v-text-field>
              </td>
              <td width="200">{{ $n(c, "currency") }}</td>
              <td width="200">
                {{ $n(form.cash[c] * c, "currency") }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-col cols="12" md="6" class="pb-10 pb-md-3">
        <v-card style="border: 1px solid var(--v-primary-base)">
          <v-card-title class="pt-2" style="font-size: 20px !important"
            >Resumen</v-card-title
          >
          <v-simple-table>
            <tbody>
              <tr>
                <td>Total Teórico</td>
                <td>
                  {{ $n(real_total_teoric, "currency") }}
                </td>
              </tr>

              <tr>
                <td>Total Real</td>
                <td>{{ $n(total_real, "currency") }}</td>
              </tr>

              <tr class="border-top">
                <td>Descuadre</td>
                <td>
                  {{ $n(real_total_teoric - total_real, "currency") }}
                </td>
              </tr>
              <tr class="border-top">
                <td v-if="open">Caja inicio jornada</td>
                <td v-else>Caja siguiente jornada</td>
                <td>
                  <v-text-field
                    dense
                    class="colorBarrita"
                    hide-details
                    v-model="form.next_day"
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </base-dialog>
</template>
<script>
import { mapActions } from "vuex";
import * as moment from "moment";
export default {
  data() {
    return {
      form: {
        theory: null,
        real: null,
        date: null,
        open: true,
        cash: {
          500: 0,
          200: 0,
          100: 0,
          50: 0,
          20: 0,
          10: 0,
          5: 0,
          2: 0,
          1: 0,
          0.5: 0,
          0.2: 0,
          0.1: 0,
          0.05: 0,
          0.02: 0,
          0.01: 0,
        },
      },
      action: "save",
      last_open: null,
    };
  },
  mounted() {
    this.fetchLast();
  },
  props: {
    total_teoric: { type: Number, required: true },
  },
  computed: {
    total_real() {
      let total = 0;
      for (let c in this.form.cash) {
        total += this.form.cash[c] * c;
      }
      this.form.next_day = total;
      return total;
    },
    real_total_teoric() {
      if (this.form.theory) return this.form.theory;
      return this.total_teoric;
    },
    open() {
      if (this.action.edit) return this.form.open;
      if (this.last_open) {
        if (this.last_open.date == moment().format("YYYY-MM-DD")) {
          return !this.last_open.open;
        }
      }
      return true;
    },
    disabled() {
      console.log(this.last_open.date);
      console.log(this.form.date);
      if (
        this.last_open &&
        this.last_open.date == moment().format("YYYY-MM-DD") &&
        !this.last_open.open
      )
        return true;
      return false;
    },
  },
  methods: {
    sortNumbers(a, b) {
      a = +a;
      b = +b;
      if (a < b) return 1;
      else if (a === b) return 0;
      else return -1;
    },
    ...mapActions("cash", ["addCash", "updateCash", "getLastCash"]),
    save() {
      this.form.real = this.total_real;
      if (this.action == "save") {
        this.form.theory = this.total_teoric;
        this.form.open = this.open;
        this.form.date = moment().format("YYYY-MM-DD");
        this.addCash(this.form).then(() => {
          this.$emit("update");
          this.fetchLast();
          this.$refs.dialog.close();
        });
      } else {
        this.updateCash({ id: this.id, cash: this.form }).then(() => {
          this.$emit("update");
          this.$refs.dialog.close();
        });
      }
    },
    fetchLast() {
      this.getLastCash().then((res) => {
        this.last_open = res;
      });
    },
    edit(cash) {
      this.action = "edit";
      this.form = cash;
      this.id = cash.id;
      this.$refs.dialog.open();
    },
  },
};
</script>